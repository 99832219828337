import React, { useState } from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { isSafari, isMobileSafari } from "react-device-detect"

import Nav from "../components/Nav"
import Footer from "../components/Footer"
import ArchiveInfo from "../components/ArchiveInfo"
import Chip from "@material-ui/core/Chip"
import CheckIcon from "@material-ui/icons/Check"

import "../styles/gallery.css"
import { photos } from "../../static/Photos"

const filterParameters = [
  {
    id: "Appreciation",
    label: "Appreciation",
    name: "Appreciation",
    value: "Appreciation",
  },
  {
    id: "FeelingGrounded",
    label: "Feeling Grounded",
    name: "FeelingGrounded",
    value: "FeelingGrounded",
  },
  {
    id: "Perseverance",
    label: "Perseverance",
    name: "Perseverance",
    value: "Perseverance",
  },

  {
    id: "HumanConnection",
    label: "Human Connection",
    name: "HumanConnection",
    value: "HumanConnection",
  },
  {
    id: "HouseholdItems",
    label: "Household Items",
    name: "HouseholdItems",
    value: "HouseholdItems",
  },
]

export const imageQuery = graphql`
  query {
    allFile(
      filter: { sourceInstanceName: { eq: "images" } }
      sort: { fields: [name], order: ASC }
    ) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  }
`

export default function Gallery({ data }) {
  const gatherImages = () => {
    const imageData = data.allFile.edges.map((item, i) => {
      return {
        ...item.node,
        ...photos[i],
      }
    })

    // TODO - fix sorting order for text only submissions
    imageData.sort((a, b) => {
      const aValue = a.audioSource === "" ? Infinity : 0
      const bValue = b.audioSource === "" ? Infinity : 0

      if (aValue < bValue) return -1
      else return 1
    })

    if (isSafari || isMobileSafari) {
      const safeImageData = imageData.filter((item) => {
        if (
          item.name !== "scrabble" &&
          item.name !== "punching_bag" &&
          item.name !== "stationary_bike"
        )
          return true
        else return false
      })

      return safeImageData
    }

    return imageData
  }

  const [selectedSubmission, setSelectedSubmission] = useState(null)
  const [filters, setFilters] = useState({})
  const [displayedPhotos, setDisplayedPhotos] = useState(gatherImages())
  const [allPhotos] = useState(gatherImages())

  const toggleImageDetails = arrayIndex => {
    if (arrayIndex === selectedSubmission) {
      setSelectedSubmission(-1)
      return
    }

    setSelectedSubmission(arrayIndex)
  }

  const handleClick = item => {
    const newChosenFilters = filters

    if (!newChosenFilters[item.id]) newChosenFilters[item.id] = true
    else newChosenFilters[item.id] = false

    setFilters(newChosenFilters)

    const noFilters =
      Object.keys(newChosenFilters).length === 0 ||
      Object.values(newChosenFilters).every(value => value === false)

    if (noFilters) {
      setDisplayedPhotos(allPhotos)
      return
    }

    let newDisplayedPhotos = []

    for (const photo of allPhotos) {
      for (const filter in filters) {
        const filterIsToggled = filters[filter]
        const photoHasFilterProperty = photo.themes[filter]

        if (filterIsToggled && photoHasFilterProperty) {
          newDisplayedPhotos.push(photo)
          break
        }
      }
    }

    setDisplayedPhotos(newDisplayedPhotos)
  }

  return (
    <>
      <Nav />
      <section>
        <h1 className="title-L">The Archive</h1>
        <p className="pageSubtitle">
          The Archive includes all submissions in their original form. View the
          images, and listen to the audio recordings at random, or use the
          Sorting tool to discover the salient themes and patterns that emerged
          from these stories.
        </p>
        <div className="grid-container">
          <div className="filter-col">
            <p className="title-S">Filter Entries</p>
            <div className="filters">
              {filterParameters.map((item, i) => (
                <Chip
                  className={filters[item.id] ? "selected" : null}
                  icon={
                    filters[item.id] ? (
                      <CheckIcon style={{ color: "F2F8F8" }} />
                    ) : null
                  }
                  key={i}
                  onClick={() => handleClick(item)}
                  label={item.label}
                ></Chip>
              ))}
            </div>
          </div>
          <div className="grid">
            {displayedPhotos.map((image, i) => {
              const imageSrc = getImage(image)
              return (
                <ArchiveInfo
                  key={i}
                  image={
                    <GatsbyImage
                      width={100}
                      alt={image.title}
                      image={imageSrc}
                      imgStyle={{ mixBlendMode: "multiply" }}
                      onClick={() => toggleImageDetails(i)}
                    />
                  }
                  toggleInfo={toggleImageDetails}
                  arrayIndex={i}
                  imageData={image}
                  willShowInfo={i === selectedSubmission}
                />
              )
            })}
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
